import { useEffect, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import styles from './ModalTask.module.scss'
import Modal from '../Modal'
import Input from '../Input'
import Editor from '../Editor'
import { useCreateTask } from '../../utils/hooks/useCreateTask'
import { useUpdateTask } from '../../utils/hooks/useUpdateTask'
import LoadingDown from '../LoadingDown'
import TimeChoice from '../TimeChoice'

enum TimeUnit {
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
}

const ModalTask = ({
  openValue,
  ChangeOpen,
  data,
  notice,
  id,
  prepTime,
}: {
  openValue: boolean
  ChangeOpen: (val: boolean) => void
  data?: any
  notice?: any
  id?: any
  prepTime?: any
}) => {
  const [title, setTitle] = useState<string>('')
  const [editorValue, setEditorValue] = useState<string>('')
  const [duration, setDuration] = useState<string>('')
  const [week, setWeek] = useState<string>('')
  const [day, setDay] = useState<string>('')
  const [daysTillEnd, setDaysTillEnd] = useState<string>('')
  const [video, setVideo] = useState<any>([])
  const [taskId, setTaskId] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const { handleCreate, isLoading: isLoadCreate }: any = useCreateTask()
  const { handleUpdate, isLoading: isLoadUpdate }: any = useUpdateTask()
  const [timeUnit, setTimeUnit] = useState<TimeUnit>(TimeUnit.Minutes)

  useEffect(() => {
    if (data) {
      setEditorValue(data?.descriptions)
      setTitle(data?.title)
      setDuration(data?.duration)
      setVideo(data?.video)
      setTaskId(data?.id)
      setWeek(data?.week)
      setDay(data?.day)
      setDaysTillEnd(data?.daysTillEnd)
    }
  }, [data])

  const CloseModal = (): void => {
    ChangeOpen(false)
  }

  const handleVideoUrlChange = (url: string) => {
    setVideo(url)
  }

  const convertToMinutes = (value: string, unit: TimeUnit): number => {
    const numericValue = parseFloat(value)
    switch (unit) {
      case TimeUnit.Hours:
        return numericValue * 60
      case TimeUnit.Days:
        return numericValue * 24 * 60
      default:
        return numericValue
    }
  }

  const handleUnitChange = (unit: TimeUnit) => {
    setTimeUnit(unit)
  }

  const handleEditorChange = (content: string) => {
    setEditorValue(content)

    const iframeRegex = /&lt;iframe[^>]*src=["'](.*?)["'][^>]*&gt;&lt;\/iframe&gt;/g
    const matches = content?.match(iframeRegex)

    if (matches) {
      matches?.forEach((match) => {
        const srcRegex = /src=["'](.*?)["']/
        const srcMatch = match?.match(srcRegex)
        if (srcMatch) {
          const href = srcMatch[1]
          setVideo((prevVideo: any) => {
            const videoArray = Array.isArray(prevVideo) ? prevVideo : []
            if (!videoArray.includes(href)) {
              return [...videoArray, href]
            }
            return videoArray
          })
        }
      })
    }
  }

  const extractUrlsFromDescription = (description: string) => {
    const urlRegex = /&lt;iframe[^>]*src=["'](.*?)["'][^>]*&gt;&lt;\/iframe&gt;/g
    const matches = description?.match(urlRegex)
    const urls: any = []

    if (matches) {
      matches.forEach((match) => {
        const hrefRegex = /src=["'](.*?)["']/
        const hrefMatch = match?.match(hrefRegex)

        if (hrefMatch) {
          const href = hrefMatch[1]
          urls.push(href)
        }
      })
    }

    return urls
  }

  function isTaskValid(
    totalCampaignDuration: number,
    taskDuration: number,
    startWeek?: number,
    startDay?: number,
  ): boolean {
    if (startWeek === undefined && startDay === undefined) {
      return taskDuration <= totalCampaignDuration
    } else if (startWeek !== undefined && startDay !== undefined) {
      const remainingCampaignDuration =
        totalCampaignDuration - ((startWeek - 1) * 7 + startDay) * 24 * 60

      return taskDuration <= remainingCampaignDuration
    }
    return false
  }

  const CreateTask = async () => {
    if (!title || !editorValue || !duration || isLoadCreate) return

    const dura = convertToMinutes(duration, timeUnit)

    if (
      !isTaskValid(
        prepTime,
        dura,
        week ? parseInt(week) : undefined,
        day ? parseInt(day) : undefined,
      )
    ) {
      setError(true)
      return
    }

    const data = {
      title,
      descriptions: editorValue,
      duration: dura.toString(),
      week: parseInt(week),
      day: parseInt(day),
      daysTillEnd: parseInt(daysTillEnd),
      templateId: id,
      video: video?.length > 0 ? video : null,
    }

    const isSuccess = await handleCreate(data)
    if (isSuccess) {
      notice(isSuccess)
      ChangeOpen(false)
    }
  }

  const UpdateTask = async () => {
    if (!title || !editorValue || !duration || isLoadUpdate) return

    if (data) {
      const dura = convertToMinutes(duration, timeUnit)
      if (
        !isTaskValid(
          prepTime,
          dura,
          week ? parseInt(week) : undefined,
          day ? parseInt(day) : undefined,
        )
      ) {
        setError(true)
        return
      }

      const videos = extractUrlsFromDescription(editorValue)

      setVideo(videos)
      const datas = {
        taskId: data?.id,
        title,
        descriptions: editorValue,
        duration: dura.toString(),
        week: parseInt(week),
        day: parseInt(day),
        daysTillEnd: parseInt(daysTillEnd),
        templateId: id,
        video: videos?.length > 0 ? videos : null,
      }

      const isSuccess = await handleUpdate(datas)

      if (isSuccess) {
        notice(isSuccess)
        ChangeOpen(false)
      }
    }
  }

  return (
    <div className={styles.task}>
      <Modal open={openValue} onClose={CloseModal} maxWidth={'700px'}>
        <div className={styles.main}>
          <h1 className={styles.title}>{data ? 'Edit ' : 'Create '} Task</h1>
          <div className={styles.content}>
            <Input
              placeholder={'Enter title task'}
              label={'Title'}
              value={title}
              error={false}
              onChange={(value) => setTitle(value)}
            />
            <div className={styles.selectors}>
              <TimeChoice text={'Duration'} value={timeUnit} onChange={handleUnitChange} />
              <Input
                placeholder={'Enter task week'}
                label={''}
                value={week}
                error={false}
                type={'number'}
                onChange={(value) => setWeek(value)}
              />
              <Input
                placeholder={'Enter task day'}
                label={''}
                value={day}
                error={false}
                type={'number'}
                onChange={(value) => setDay(value)}
              />
            </div>
            <Input
              placeholder={'Enter duration task'}
              label={''}
              value={duration}
              error={error}
              type={'number'}
              onChange={(value) => {
                if (error) {
                  setError(false)
                }
                setDuration(value)
              }}
              message={error ? 'Task duration exceeds template limits' : ''}
            />
            <Input
              placeholder={'Enter days until campaign end'}
              label={''}
              value={daysTillEnd}
              error={false}
              type={'number'}
              onChange={(value) => setDaysTillEnd(value)}
            />
            <Editor value={editorValue} onChange={handleEditorChange} />
            <button className={styles.btn} onClick={data ? UpdateTask : CreateTask}>
              <p className={styles.text}>{data ? 'Update ' : 'Create '} task</p>
            </button>
          </div>
        </div>
      </Modal>
      {isLoadCreate && <LoadingDown isVisible={isLoadCreate} />}
      {isLoadUpdate && <LoadingDown isVisible={isLoadUpdate} />}
    </div>
  )
}

export default ModalTask
