// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { ReactComponent as DashboardIcon } from '../../assets/images/dashboard.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { ReactComponent as DoneIcon } from '../../assets/images/circle_done.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { ReactComponent as DocumentIcon } from '../../assets/images/document.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { ReactComponent as CalendarIcon } from '../../assets/images/cal.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { ReactComponent as PreferencesIcon } from '../../assets/images/preferences.svg'

export const arrOfNav = [
  {
    title: 'Dashboard',
    icon: DashboardIcon,
    link: '/dashboard',
  },
  {
    title: 'Completed tasks',
    icon: DoneIcon,
    link: '/completed-tasks',
  },
  // {
  //   title: 'Details',
  //   icon: DocumentIcon,
  //   link: '/new-campaign',
  // },
  {
    title: 'Calendar',
    icon: CalendarIcon,
    link: '/calendar',
  },
  // {
  //   title: 'Preferences',
  //   icon: PreferencesIcon,
  //   link: '/new-campaign',
  // },
]

export const arrStep = [
  {
    title: 'Pick Your Template',
    description: 'Choose your campaign type from the proven plug-and-play templates below',
  },
  {
    title: 'Choose your launch date',
    description: 'When do you what to launch?',
  },
]
