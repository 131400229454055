import { FC, memo, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import styles from './ToDoItem.module.scss'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import rocket from '../../assets/images/rocket_red_big.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import rocket_hover from '../../assets/images/rocket_hover.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import star_pro from '../../assets/images/star_pro.svg'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import star_pro_yellow from '../../assets/images/star_pro_yellow.svg'
import TextTruncate from '../TextTruncate'
import { convertMinutes } from '../../utils/format/format'

interface ToDoItemProps {
  click?: any
  data?: any
}

const ToDoItem: FC<ToDoItemProps> = ({ click, data }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleItemClick = () => {
    if (click && !data.upcoming) {
      click(data)
    }
  }

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={styles.ToDoItem}
      onClick={handleItemClick}
    >
      <div className={styles.content}>
        <div className={styles.img_box}>
          <div className={styles.img}>
            <img src={isHovered ? rocket_hover : rocket} alt='rocket' />
          </div>
          {!isHovered && data?.author && (
            <div className={styles.pro}>
              <p>Pro</p>
              <img src={star_pro_yellow} alt='star' />
            </div>
          )}
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            <TextTruncate
              text={data?.name}
              maxCharactersDesktop={50}
              maxCharactersTablet={50}
              maxCharactersMobile={50}
              maxCharactersMobileMin={50}
              isHovered={isHovered}
            />
          </div>
          <div className={styles.description}>
            {data.author ? data.author : data.upcoming ? 'Coming soon' : 'Launch template'}
          </div>
        </div>
        {isHovered && data?.author && (
          <div className={styles.pro_hovered}>
            <p>Pro</p>
            <img src={star_pro} alt='star' />
          </div>
        )}
      </div>
      <div className={styles.content_hidden}>
        {!data.upcoming ? (
          <>
            <div className={styles.left}>
              <div className={styles.block}>
                <div className={styles.block_info}>{convertMinutes(data?.prepTime)}</div>
                <div className={styles.block_text}>Prep time</div>
              </div>
            </div>
            <div className={styles.right}>
              {data.idealPreReq && (
                <div className={styles.block}>
                  <div className={styles.block_info}>{data?.idealPreReq}</div>
                  <div className={styles.block_text}>Ideal Pre Req</div>
                </div>
              )}
            </div>
          </>
        ) : (
          <p className={styles.soon_text}>This template will be available soon!</p>
        )}
      </div>
    </div>
  )
}

export default memo(ToDoItem)
