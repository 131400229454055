import React, { ChangeEvent, memo } from 'react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import styles from './Input.module.scss'

interface InputProps {
  label: any
  value: string
  onChange: (value: string) => void
  placeholder?: string
  error?: boolean
  type?: string
  marginTop?: string
  maxLength?: number
  message?: string
  disabled?: boolean
}

const Input: React.FC<InputProps> = memo(
  ({
    label,
    value,
    onChange,
    placeholder,
    error,
    type,
    marginTop,
    maxLength,
    message,
    disabled,
  }) => {
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      onChange(value)
    }

    return (
      <div className={`${styles.inputContainer} ${error ? styles.error : ''}`}>
        <label className={styles.label}>{label}</label>
        <input
          style={{ marginTop: marginTop }}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={handleInputChange}
          className={styles.input}
          maxLength={maxLength}
          disabled={disabled}
        />
        {message && <p className={styles.message}>{message}</p>}
      </div>
    )
  },
)

export default Input
