// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from './ReplaceTextToHtml.module.scss'
import { memo } from 'react'

const ReplaceTextToHtml = ({ data }: any) => {
  const transformText = (text: string) => {
    return text
      ? text
          ?.replace(/<p><br><\/p>/g, '')
          .replace(/<p>/g, `<p class=${styles.text}>`)
          .replace(/<a/g, `<a class=${styles.link}`)
          .replace(/&lt;iframe[^>]*src=["'](.*?)["'][^>]*&gt;&lt;\/iframe&gt;/g, '')
      : ''
  }

  const processedText = transformText(data?.task?.descriptions)
  return <div dangerouslySetInnerHTML={{ __html: processedText }} />
}

export default memo(ReplaceTextToHtml)
