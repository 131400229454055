import React, { FC } from 'react'
import Modal from '../Modal'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import styles from './ModalRemove.module.scss'

interface ModalRemoveProps {
  setOpen: (val: boolean) => void
  text: string
  onConfirm: () => void
}

const ModalRemove: FC<ModalRemoveProps> = ({ setOpen, text, onConfirm }) => {
  return (
    <div>
      <Modal onClose={() => setOpen(false)} maxWidth={'550px'}>
        <div className={styles.remove}>
          <p className={styles.text}>{text}</p>
          <div className={styles.controls}>
            <button className={styles.cancel} onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button className={styles.action} onClick={onConfirm}>
              Remove
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ModalRemove
